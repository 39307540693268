@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'variables';

@include mat.core($custom-typography);

@include mat.all-component-themes($fs-theme);

:root {
  --background: #{mat.get-color-from-palette(mat.$gray-palette, 50)};
  --background-alternate: #{mat.get-color-from-palette(mat.$gray-palette, 100)};
  --background-darker: #{mat.get-color-from-palette(mat.$gray-palette, 300)};
  --dark-primary-text: #{$dark-primary-text};
  --dark-secondary-text: #{$dark-secondary-text};
  --dark-disabled-text: #{$dark-disabled-text};
  --dark-dividers: #{$dark-dividers};
  --dark-focused: #{$dark-focused};
  --light-primary-text: #{$light-primary-text};
  --light-secondary-text: #{$light-secondary-text};
  --light-disabled-text: #{$light-disabled-text};
  --light-dividers: #{$light-dividers};
  --light-focused: #{$light-focused};
  --primary: #{mat.get-color-from-palette($primary)};
  --primary-50: #{mat.get-color-from-palette($primary, 50)};
  --primary-100: #{mat.get-color-from-palette($primary, 100)};
  --primary-200: #{mat.get-color-from-palette($primary, 200)};
  --primary-300: #{mat.get-color-from-palette($primary, 300)};
  --primary-400: #{mat.get-color-from-palette($primary, 400)};
  --primary-500: #{mat.get-color-from-palette($primary, 500)};
  --primary-600: #{mat.get-color-from-palette($primary, 600)};
  --primary-700: #{mat.get-color-from-palette($primary, 700)};
  --primary-800: #{mat.get-color-from-palette($primary, 800)};
  --primary-900: #{mat.get-color-from-palette($primary, 900)};
  --primary-A100: #{mat.get-color-from-palette($primary, A100)};
  --primary-A200: #{mat.get-color-from-palette($primary, A200)};
  --primary-A400: #{mat.get-color-from-palette($primary, A400)};
  --primary-A700: #{mat.get-color-from-palette($primary, A700)};
  --accent: #{mat.get-color-from-palette($accent)};
  --accent-50: #{mat.get-color-from-palette($accent, 50)};
  --accent-100: #{mat.get-color-from-palette($accent, 100)};
  --accent-200: #{mat.get-color-from-palette($accent, 200)};
  --accent-300: #{mat.get-color-from-palette($accent, 300)};
  --accent-400: #{mat.get-color-from-palette($accent, 400)};
  --accent-500: #{mat.get-color-from-palette($accent, 500)};
  --accent-600: #{mat.get-color-from-palette($accent, 600)};
  --accent-700: #{mat.get-color-from-palette($accent, 700)};
  --accent-800: #{mat.get-color-from-palette($accent, 800)};
  --accent-900: #{mat.get-color-from-palette($accent, 900)};
  --accent-A100: #{mat.get-color-from-palette($accent, A100)};
  --accent-A200: #{mat.get-color-from-palette($accent, A200)};
  --accent-A400: #{mat.get-color-from-palette($accent, A400)};
  --accent-A700: #{mat.get-color-from-palette($accent, A700)};
  --warn: #{mat.get-color-from-palette($warn)};
  --warn-50: #{mat.get-color-from-palette($warn, 50)};
  --warn-100: #{mat.get-color-from-palette($warn, 100)};
  --warn-200: #{mat.get-color-from-palette($warn, 200)};
  --warn-300: #{mat.get-color-from-palette($warn, 300)};
  --warn-400: #{mat.get-color-from-palette($warn, 400)};
  --warn-500: #{mat.get-color-from-palette($warn, 500)};
  --warn-600: #{mat.get-color-from-palette($warn, 600)};
  --warn-700: #{mat.get-color-from-palette($warn, 700)};
  --warn-800: #{mat.get-color-from-palette($warn, 800)};
  --warn-900: #{mat.get-color-from-palette($warn, 900)};
  --warn-A100: #{mat.get-color-from-palette($warn, A100)};
  --warn-A200: #{mat.get-color-from-palette($warn, A200)};
  --warn-A400: #{mat.get-color-from-palette($warn, A400)};
  --warn-A700: #{mat.get-color-from-palette($warn, A700)};
}
