@use '@angular/material' as mat;
@import "variables";
@import "theme";
@import "~ngx-toastr/toastr.css";
@import "fonts";
@import "app/utils/ghost";

table > caption {
  display: none;
}

.mat-drawer-container {
  background-color: #FFF !important;
}

button:not(.mat-icon-button) {
  mat-spinner {
    display: inline-block !important;
    margin-right: 6px;
  }
}

button.mat-icon-button {
  mat-spinner, mat-progress-spinner {
    margin: auto;
  }
}

mat-icon.success {
  color: mat.get-color-from-palette(mat.$green-palette, 500) !important;
}

mat-icon.error {
  color: mat.get-color-from-palette($warn) !important;
}

.mat-text-primary {
  color: mat.get-color-from-palette($primary) !important;
}

.mat-text-warn {
  color: mat.get-color-from-palette($warn) !important;
}

.mat-text-secondary {
  color: $dark-secondary-text !important;
}

.align-center {
  text-align: center;
}

.icon-btn .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  .mat-spinner, .mat-icon {
    margin-right: .5em;
  }
}

.fs-organisation-access-denied {
  opacity: .6;
  cursor: not-allowed;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    pointer-events: fill;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .2);
  }
  &:after {
    content: 'lock';
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: 'Material Icons', sans-serif;
    transform: translate(-50%, -50%);
    font-size: 2em;
    text-shadow: 1px 1px 2px white, 0 0 1em gray, 0 0 0.2em gray;
    z-index: 11;
  }
}

.mat-icon {
  &.mat-icon-outlined {
    //noinspection CssNoGenericFontName
    font-family: 'Material Icons Outlined', sans-serif;
  }
  &.mat-icon-small {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
  }

  &.mat-icon-2x {
    font-size: 48px;
    width: 48px;
    height: 48px;
  }

  &.mat-icon-3x {
    font-size: 60px;
    width: 60px;
    height: 60px;
  }
}


[fsDeliveryReportStatus] {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
  &.delivery-report-status-read, &.delivery-report-status-delivered {
    color: mat.get-color-from-palette(mat.$green-palette, 500);
  }
  &.delivery-report-status-error {
    color: mat.get-color-from-palette($warn, 500);
  }
  &.delivery-report-status-pending {
    color: mat.get-color-from-palette(mat.$orange-palette, 500);
  }
}

.button-row button,
.button-row a {
  margin-right: 8px;
}

button.dense {
  line-height: 24px;
}

button.btn-uppercase {
  text-transform: uppercase;
}

.mat-button, .mat-flat-button, .mat-raised-button {
  mat-icon:not(.icon-right) {
    width: 18px;
    height: 18px;
    font-size: 18px;
    margin-right: 8px;
    margin-left: -4px;
  }
  mat-icon.icon-right {
    width: 18px;
    height: 18px;
    font-size: 18px;
    margin-left: 8px;
    margin-right: -4px;
  }
}

mat-card-actions, mat-dialog-actions {
  &.btn-uppercase {
    button {
      text-transform: uppercase;
    }
  }
  &.align-right {
    justify-content: flex-end;
  }
}

mat-card-title.panel-title-like {
  font-size: mat.font-size($custom-typography, subheading-1);
  font-weight: mat.font-weight($custom-typography, subheading-1);
  margin-bottom: 20px;
}

mat-card-title.panel-subtitle-like {
  font-size: mat.font-size($custom-typography, subheading-2);
  font-weight: mat.font-weight($custom-typography, subheading-2);
  margin-bottom: 12px;
}

mat-card-title.card-title-sm {
  font-size: mat.font-size($custom-typography, subheading-2) !important;
  margin-top: 8px !important;
  margin-bottom: 1.5em;
}

.mat-menu-panel.mat-menu-with-avatar {
  button {
    display: flex;
    align-items: center;
    &:disabled {
      img {
        filter: grayscale(100%);
      }
    }
    img {
      margin-right: 10px;
    }
  }
}

img[fsAvatar][src$=anonymous] {
  background-color: $background;
}

.mat-dialog-container {
  position: relative;
}

mat-card .mat-card-header-text {
  &:first-child {
    margin-left: 0 !important;
  }
  mat-card-title:last-child {
    margin-bottom: 16px;
  }
}

dl.dl-horizontal {
  dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
  }
  dd {
    margin-left: 180px;
    &::before, &::after {
      display: table;
      content: " ";
    }
    &::after {
      clear: both;
    }
  }
}

mat-progress-bar.absolute-top {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
}

.mat-option-text {
  display: flex !important;
}

.language-icon {
  width: 24px;
  margin-right: 8px;
}

.inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.help-circle {
  font-size: 16px;
  height: 16px!important;
  width: 16px!important;
  cursor: pointer;
}
