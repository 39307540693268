@import "ghost-animation";

.ghost {
  overflow: hidden;
  margin-bottom: -6px;

  .lines {

    h2, h3, p, span {
      height: 24px;
      padding: 0;
      align-content: center;
      position: relative;
      background-color: rgba(235, 235, 235, 0.514);
      border-radius: 4px;
      @include animate-ghost-line;
    }

    h2 {
      width: 50%;
    }

    h3 {
      width: 85%;
    }

    p {
      width: 70%;
      & + p {
        width: 85%;
        & + p {
          width: 55%;
        }
      }
    }

    span {
      display: inline-block;
      margin-top: 6px;
      margin-bottom: 6px;
      width: 100px;
      max-width: 100%;
      margin-right: 10px;
    }
  }
}
