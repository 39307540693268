@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'font-awesome/scss/variables';

$custom-typography: mat.define-typography-config(
  $font-family: 'OpenSans, "Helvetica Neue", sans-serif'
);

$colors: (
  primary: #41BCD7,
  secondary: #32db64,
  danger: #f53d3d,
  light: #f4f4f4,
  dark: #222,
  favorite: #69BB7B,
  twitter: #53ACEB,
  github: #000000,
  instagram: #235D8D,
  fsorange: #E85419,
  fsdarkblue: #2B90A6,
  fslightblue:#41BCD7,
  fsgray: #404040,
  fslightgray:#EEEEEE,
  white: #FFFFFF,
  green: #64AD05
);

$phone: "only screen and (min-width: 320px) and (max-width: 479px)";
$phablet: "only screen and (min-width: 480px) and (max-width: 767px)";
$tablet: "only screen and (min-width: 768px) and (max-width: 991px)";
$desktop: "only screen and (min-width: 992px))";

$background: #F5F5FA;

$fs-blue: (
  50: #e2f7fb,
  100: #b6ebf5,
  200: #89deee,
  300: #60d0e6,
  400: #49c6de,
  500: #41BBD7,
  600: #3cacc4,
  700: #3597a9,
  800: #308391,
  900: #266066,
  A100: #89deee,
  A200: #41BBD7,
  A400: #3597a9,
  A700: #266066,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$fs-orange: (
  50: #fff3e1,
  100: #ffe0b5,
  200: #ffcc85,
  300: #ffb856,
  400: #ffa834,
  500: #ff9920,
  600: #ff8d1e,
  700: #f97d1d,
  800: #f26e1b,
  900: #e85419,
  A100: #ffcc85,
  A200: #ffa834,
  A400: #ff831d,
  A700: #FF5919,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

$primary: mat.define-palette($fs-blue);
$warn: mat.define-palette(mat.$red-palette);
$accent: mat.define-palette($fs-orange, 700, 900, 300);
$fs-theme: mat.define-light-theme($primary, $accent, $warn);

$default-border-radius: 4px;
