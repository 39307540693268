$fa-font-path: "/font-awesome/fonts";
@import "font-awesome/scss/font-awesome.scss";

$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
@import 'material-design-icons-iconfont/src/material-design-icons';

@font-face {
  font-family: Comfortaa;
  src: url('./assets/fonts/Comfortaa/Comfortaa-Bold.woff2') format('woff2'),
       url('./assets/fonts/Comfortaa/Comfortaa-Bold.ttf') format('truetype');/* Safari, Android, iOS */
  font-weight: bold;
}

@font-face {
  font-family: Comfortaa;
  src: url('./assets/fonts/Comfortaa/Comfortaa-Regular.woff2') format('woff2'),
       url('./assets/fonts/Comfortaa/Comfortaa-Regular.ttf') format('truetype');/* Safari, Android, iOS */
  font-weight: normal;
}

@font-face {
  font-family: OpenSans;
  src: url('./assets/fonts/Open_Sans/OpenSans-Regular.woff2') format('woff2'),
       url('./assets/fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
}

@font-face {
  font-family: OpenSans;
  src: url('./assets/fonts/Open_Sans/OpenSans-Italic.woff2') format('woff2'),
       url('./assets/fonts/Open_Sans/OpenSans-Italic.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: OpenSans;
  src: url('./assets/fonts/Open_Sans/OpenSans-SemiBold.woff2') format('woff2'),
       url('./assets/fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 500;
}

@font-face {
  font-family: OpenSans;
  src: url('./assets/fonts/Open_Sans/OpenSans-Bold.woff2') format('woff2'),
       url('./assets/fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: bold;
}

@font-face {
  font-family: OpenSans;
  src: url('./assets/fonts/Open_Sans/OpenSans-Light.woff2') format('woff2'),
       url('./assets/fonts/Open_Sans/OpenSans-Light.ttf') format('truetype'); /* Safari, Android, iOS */
  font-weight: 300;
}

@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
/*

@font-face {
  font-family: 'Material Icons Outlined';
  src: url('assets/fonts/materialiconsoutlined/v5-gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUcel5euIg.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
} */

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

